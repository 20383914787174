/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useState, useEffect } from 'react'
import { useRouter } from 'next/router'
import Head from 'next/head'

import Custom404 from 'pages/404'

const Link = () => {
  const router = useRouter()
  const [invalidLink, setInvalidLink] = useState<boolean>(false)

  useEffect(() => {
    if (router.query) {
      const { linkId } = router.query
      if (linkId) {
        const query = { linkId }

        if (typeof linkId === 'string' || linkId instanceof String) {
          setInvalidLink(false)
          router.replace({ pathname: '/', query }, `/${linkId}`)
        } else {
          setInvalidLink(true)
        }
      }
    }
  }, [router])

  return (
    <div>
      <Head>
        <title>Magpie Payment Link</title>
        <meta name="robots" content="noindex" />
      </Head>
      {invalidLink && <Custom404 />}
    </div>
  )
}

export default Link
